$color-background: #fafafa;
$color-footer-mobile-1: darken($color-background, 2%);
$color-footer-mobile-2: darken($color-background, 10%);
$color-background-code: darken($color-background, 2%);
$color-border: #666;
$color-meta: #666;
$color-meta-code: lighten($color-meta, 10%);
$color-link: rgba(86, 124, 119, .4);
$color-text: #22272a;
$color-accent-1: #cc2a41;
$color-accent-2: rgba(86, 124, 119, .8);
$color-accent-3: #666;
$color-quote: #cc2a41;